import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import axios from "axios";
import Utils from "../../utils/utils";
import Spinner from "../../components/spinner/spinner";
import { UserContext } from "../../components/layout/Layout";
import RazorPayUtils from "../../utils/razorpay-utils";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
	Alert,
	Checkbox,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AddHomeIcon from "@mui/icons-material/AddHome";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Divider from '@mui/material/Divider';
import Chip from "@mui/material/Chip";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { useTheme } from "@mui/material/styles";
import * as SharedHelpers from "./../../utils/SharedHelpers";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import _ from "lodash";
import "./BookAppointment.css";
import { Appointment } from "./models/Appointment";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Call } from "@mui/icons-material";
const customTheme = createTheme({
	components: {
		MuiInputLabel: {
			styleOverrides: {
				asterisk: {
					color: "red",
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				asterisk: {
					color: "red",
				},
			},
		},
	},
});
const package_amount = { 30: 2000, 60: 4000 };
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
};

const staffSelection = {
	label: "Staff Selection",
	id: "staff-selection",
};

const sessionType = {
	label: "Session Type",
	id: "booking-container",
};

const sessionDuration = {
	label: "Select session duration",
	id: "duration-section",
};

const slotSelection = {
	label: "Slot Selection",
	id: "time-slots",
};

const confirmation = {
	label: "Confirmation",
	id: "confirm-section",
};

const clinicStep = {
	label: "Clinic Selection",
	id: "clinic-cards-section",
	stepIndex: 2,
};

const signupStep = {
	label: "Sign Up",
	id: "signup-section",
};

var steps = [
	staffSelection,
	sessionType,
	sessionDuration,
	slotSelection,
	confirmation,
];

export default function BookAppointment({ onLoginSuccess }) {
	const [patientDetails, setpatientDetails] = useState(useContext(UserContext));
	const params = useParams();
	const [activeStep, setActiveStep] = useState(0);
	const [doctors, setDoctors] = useState(steps);
	const [locations, setLocations] = useState([]);
	const [apptSlots, setApptSlots] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const theme = useTheme();
	const [showPassword, setShowPassword] = React.useState(false);
	const [appointment, setAppointment] = React.useState(new Appointment());
	const [isLoading, setIsLoading] = useState(false);
	const [bookedAppts, setBookedAppts] = useState([]);
	var heCode = patientDetails?.patientNo ? referenceCode : params.heCode;
	const [haveAccount, setHaveAccount] = useState(false);
	const [loginOrRegisterObj, setLoginOrRegisterObj] = useState({});
	const [loginErrorMessage, setLoginErrorMessage] = useState("");
	const [validations, setValidations] = useState({});
	const [referenceCode, setReferenceCode] = useState(
		localStorage.getItem("referenceCode") || null
	);
	const [sessionTypes, setSessionTypes] = useState([]);
	const [durations, setDurations] = useState([]);
	const [status, setStatus] = useState("");
	const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
	const [noSlotMessage, setNoSlotMessage] = useState('');

	useEffect(() => {
		if (!patientDetails?.patient_no) {
			steps = steps.filter((item) => item["id"] !== signupStep.id);
			let index = _.findIndex(steps, ["id", slotSelection.id]);
			if (index >= 0) {
				steps.splice(index + 1, 0, signupStep);
			}
		} else {
			if (!heCode) heCode = referenceCode;
		}
		getConsultants();
	}, [referenceCode]);

	const getLocations = (heid, setAppt = true) => {
		axios
			.get(`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/locationlist/${heid}/`)
			.then((res) => {
				setLocations(res.data);
				if (res.data && res.data.length > 0 && setAppt) {
					// getConsultants(res.data[0].locId);
					setAppointment({ ...appointment, clinic: res.data[0].locId });
					setActiveStep(getStepNo(clinicStep.id) + 1);
				}
			});
	};

	const getConsultants = (clinic = null, setClinic = false) => {
		setIsLoading(true);
		axios
			.get(
				`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/doctorlistbyhecode/${heCode}/`
			)
			.then((res) => {
				let consultants = res.data || [];
				// if (res.data) {
				//     consultants = consultants.concat(res.data || []);
				//     consultants = consultants.concat(res.data || []);
				// }

				setDoctors(consultants);
				let consultant = null;
				if (params.userId)
					consultant = _.find(consultants, ["userId", params.userId]);
				else {
					consultant = consultants[4];
				}
				setSessionAndDurationByConsultant(consultant);
			});
	};

	const setSessionAndDurationByConsultant = (consultant, setClinic = false) => {
		let sType = "";
		if (
			consultant?.userId &&
			consultant.sessionTypes &&
			consultant.sessionTypes.length > 0
		) {
			// const websiteServices = _.filter(consultant.billingServices, ['isWebsiteService', true]);
			// let sessionTypeList = [];
			// websiteServices.forEach(element => {
			//     if (element.sessionType && element.sessionType.length > 0) {
			//         sessionTypeList = _.union(sessionTypeList, element.sessionType);
			//     }
			// });
			setSessionTypes(consultant.sessionTypes);
			if (consultant.sessionTypes.length > 0) {
				if (consultant.sessionTypes.includes("Online")) sType = "Online";
				else sType = consultant.sessionTypes[0];
			}
		}
		setIsLoading(false);
		if (!params?.apptId) {
			if (consultant) {
				if (setClinic) {
					scrollToId("clinic-cards-section");
					resetAppointment([
						"consultant",
						"service_name",
						"duration",
						"package",
						"package_amount",
						"appointment_date",
						"times_of_the_day",
						"appointment_time",
					]);
					setAppointment({
						...appointment,
						["clinic"]: clinic,
						consultant: consultant,
						session_type: sType,
					});
					setActiveStep(3);
				} else {
					setAppointment({
						...appointment,
						consultant: consultant,
						session_type: sType,
					});
					setActiveStep(2);
				}
			}
		} else {
			getAppointmentByAppointmentId();
		}
		if (sType === "In-person") {
			let index = _.findIndex(steps, ["label", clinicStep.label]);
			if (index < 0) {
				steps.splice(clinicStep.stepIndex, 0, clinicStep);
			}
			getLocations(appointment?.consultant?.heId);
		} else {
			steps = steps.filter((item) => item["label"] !== clinicStep.label);
		}
		getDurationList(consultant, sType);
	};

	const getAppointmentByAppointmentId = () => {
		axios
			.get(
				`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/get-appt-by-id/${params.heId}/${params.apptId}/`
			)
			.then((res) => {
				const combinedStartDateTime = moment(res.data.start).add(
					moment.duration(res.data.startTime)
				);
				const combinedEndDateTime = moment(res.data.end).add(
					moment.duration(res.data.endTime)
				);

				const durationDifference = moment.duration(
					combinedEndDateTime.diff(combinedStartDateTime)
				);

				// console.log(durationDifference.humanize()); // Output: "30 minutes"
				// console.log(durationDifference.asMinutes()); // Output: 30 (duration in minutes)
				if (res.data.modeOfAdmission === "In-person") {
					getLocations(res.data.heId, false);
				}
				let apptDate = moment(res.data.start)["_d"];
				let obj = {
					apptId: res.data.apptId,
					heId: res.data.heId,
					session_type: res.data.modeOfAdmission,
					status: "Rescheduled",
					consultant: res.data.doctor,
					clinic: res.data.locName.locId,
					times_of_the_day: 0,
					appointment_date: apptDate,
					appointment_time: null, //moment(res.data.startTime, "HH:mm").format("hh:mm A")
					duration: durationDifference.asMinutes(),
					package: "",
					package_amount: res.data.package_amount,
				};
				setAppointment(obj);
				if (obj?.consultant) {
					let configs = [];
					if (obj.session_type === "Online") {
						configs = obj.consultant["teleTimings"];
					} else {
						configs = obj.consultant["opTimings"]?.[res?.data?.locId];
					}
					setApptSlots(
						SharedHelpers.getApptTimeSlots(
							apptDate,
							configs,
							obj?.session_type,
							appointment?.duration
						)
					);
				}
				getBookedAppts(apptDate, res.data.doctor.heId, res.data.doctor.userId);
			});
	};

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleTabChange = (event, newValue) => {
		setAppointment({
			...appointment,
			["times_of_the_day"]: newValue,
			appointment_time: null,
		});
	};

	const handleLabelClick = () => {
		setIsOpen(!isOpen);
	};

	const handleChange = (event, newSessionType) => {
		newSessionType = newSessionType ?? appointment["session_type"];
		if (newSessionType) {
			scrollToId("booking-container");
			if (newSessionType === "In-person") {
				let index = _.findIndex(steps, ["label", clinicStep.label]);
				if (index < 0) {
					steps.splice(clinicStep.stepIndex, 0, clinicStep);
				}
				getLocations(appointment.consultant.heId);
			} else {
				steps = steps.filter((item) => item["label"] !== clinicStep.label);
				// if (!heCode)
				//     heCode = referenceCode;
				// getConsultants(locations[0]['locId']);
			}
			resetAppointment([
				"clinic",
				"service_name",
				"duration",
				"package",
				"package_amount",
				"appointment_date",
				"times_of_the_day",
				"appointment_time",
			]);
			appointment["session_type"] = newSessionType;
			getDurationList(appointment?.consultant, newSessionType);
			setAppointment({ ...appointment, ["session_type"]: newSessionType });
			setActiveStep(2);
		} else {
			setAppointment(new Appointment());
			setActiveStep(0);
		}
	};

	const getDurationList = (consultant, sType) => {
		const durationList = _.filter(consultant?.billingServices, function (o) {
			return (
				o.sessionType &&
				o.sessionType.includes(sType) &&
				o.isWebsiteService === true &&
				(o.duration ?? false) &&
				o.default_bill_amount > 0
			);
		});
		setDurations([...durationList]);
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const resetAppointment = (fields = []) => {
		if (fields.length > 0) {
			fields.forEach((element) => {
				appointment[element] = null;
			});
			appointment["times_of_the_day"] = 0;
		}
	};

	const hadleClinicChange = (e, value) => {
		if (appointment?.apptId) return false;
		if (!appointment?.session_type) {
			alert("Please choose session type to choose clinic");
			scrollToId("booking-container");
			return false;
		} else {
			scrollToId("clinic-cards-section");
			resetAppointment([
				"service_name",
				"duration",
				"package",
				"package_amount",
				"appointment_date",
				"times_of_the_day",
				"appointment_time",
			]);
			setAppointment({ ...appointment, ["clinic"]: value });
			if (value) setActiveStep(3);
			else setActiveStep(2);
		}
	};

	const handleStaffChange = (newValue) => {
		// console.log("in select change", newValue)
		scrollToId("staff-selection");
		resetAppointment([
			"service_name",
			"duration",
			"package",
			"package_amount",
			"appointment_date",
			"times_of_the_day",
			"appointment_time",
		]);

		let sType = "";
		if (
			newValue?.userId &&
			newValue.billingServices &&
			newValue.billingServices.length > 0
		) {
			// const websiteServices = _.filter(newValue.billingServices, ['isWebsiteService', true]);
			// console.log(websiteServices)
			// let sessionTypeList = [];
			// websiteServices.forEach(element => {
			//     if (element.sessionType && element.sessionType.length > 0) {
			//         sessionTypeList = _.union(sessionTypeList, element.sessionType);
			//     }
			// });
			// setSessionTypes([...sessionTypeList])
			// setDurations([])
			// if (sessionTypeList.length > 0) {
			//     if (sessionTypeList.includes('Online'))
			//         sType = 'Online';
			//     else
			//         sType = sessionTypeList[0];
			// }
			setSessionAndDurationByConsultant(newValue);
		}

		setAppointment({
			...appointment,
			["consultant"]: newValue,
			session_type: "Online",
		});
		if (newValue) setActiveStep(3);
		else {
			setAppointment(new Appointment());
			setActiveStep(0);
		}
	};

	const handleDurationChange = (e, service) => {
		if (appointment?.apptId) return false;
		if (!appointment?.consultant) {
			alert("Please choose Staff to choose Duration and Package");
			scrollToId("staff-selection");
			return false;
		} else {
			scrollToId("duration-section");
			// 'package', 'package_amount',
			resetAppointment([
				"appointment_date",
				"times_of_the_day",
				"appointment_time",
			]);
			setAppointment({
				...appointment,
				["service_name"]: service.name,
				["duration"]: service.duration,
				["package_amount"]: service.default_bill_amount,
				selectedService: service
			});
			appointment.selectedService = service;

			setActiveStep(getStepNo(sessionDuration.id) + 1);
		}
	};

	const handlePackageChange = (e, value, packageWorth) => {
		if (!appointment?.duration) {
			alert("Please choose Duration to choose Package");
			scrollToId("duration-section");
			return false;
		} else {
			scrollToId("duration-section");
			resetAppointment([
				"appointment_date",
				"times_of_the_day",
				"appointment_time",
			]);
			setAppointment({
				...appointment,
				["package"]: value,
				["package_amount"]: packageWorth,
			});
			if (value && appointment?.duration)
				setActiveStep(getStepNo(sessionDuration.id) + 1);
			else setActiveStep(getStepNo(sessionDuration.id));
		}
	};

	const handleApptDateChange = (newValue) => {
		if (!appointment?.package && !appointment?.duration) {
			alert("Please choose Duration and Package to choose Slots");
			scrollToId("staff-selection");
			return false;
		} else {
			scrollToId("time-slots");
			resetAppointment(["times_of_the_day", "appointment_time"]);
			if (appointment?.consultant && appointment?.consultant?.heId) {
				console.log(appointment)
				let slotFilters = {
					"date": moment(newValue["_d"]).format("YYYY-MM-DD"),
					"mode": appointment.session_type,
					"doctor": appointment?.consultant?.userId ?? null,
					"location": (appointment.session_type === 'Online') ? (appointment.consultant["locId"] ?? null) : (appointment?.clinic ?? null),
					"service": null,
					"sessionDuration": appointment.selectedService?.duration,
					"room": null
				}

				console.log(slotFilters)

				axios.post(`${Utils.PRIISM_API_ENDPOINT}/appointments/get-slots/by-params/${appointment?.consultant?.heId}/`, slotFilters)
					.then((res) => {
						if (_.has(res?.data, 'message')) {
							setNoSlotMessage(SharedHelpers.getUsername(appointment?.consultant) + " is not available at the selected Location, Date, or Session type.  Please choose a different option.");
							setApptSlots([]);
						}
						else {
							setApptSlots(res.data)
						}
						console.log(res)


					})
			}
			appointment.appointment_date = newValue["_d"];
			setAppointment({ ...appointment, ["appointment_date"]: newValue["_d"] });
		}
	};

	const getBookedAppts = (
		apptDate = appointment?.appointment_date,
		heId = appointment.consultant.heId,
		userId = appointment.consultant.userId
	) => {
		let filters = {
			apptDate: moment(moment(apptDate).subtract(1, "days")).toISOString(true),
			heId: heId,
			userId: userId,
			// sessionType: appointment?.session_type,
		};
		axios
			.post(
				`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/getallpatientapptsfordoctor/`,
				filters
			)
			.then((res) => {
				setBookedAppts(res.data);
			});
	};

	const handleApptTimeSoltChange = (e, value) => {
		scrollToId("time-slots");
		setAppointment({ ...appointment, ["appointment_time"]: value });
		if (value) setActiveStep(getStepNo(slotSelection["id"]) + 1);
		else setActiveStep(getStepNo(slotSelection["id"]));
	};

	const openRazorPayModal = (e) => {
		if (!appointment?.session_type) {
			alert("Session Type is required.");
			return;
		}
		if (appointment?.session_type !== "Online" && !appointment?.clinic) {
			alert("Location is required.");
			return;
		}
		if (!appointment?.duration) {
			alert("Duration is required.");
			return;
		}
		// if (!appointment?.package) {
		//     alert("Package is required.");
		//     return;
		// }
		if (!appointment?.consultant) {
			alert("Consultant is required.");
			return;
		}
		if (!appointment?.appointment_date) {
			alert("Appointment Date is required.");
			return;
		}
		if (!appointment?.appointment_time) {
			alert("Appointment Time is required.");
			return;
		}

		if (!appointment?.consent) {
			openConsentModal();
		} else {
			// first check for any existing booked appointments presetn or not for selected doctor and slot.
			console.log(appointment?.appointment_date);
			let apptDate = moment(
				moment(appointment?.appointment_date).subtract(1, "days")
			).toISOString(true);
			console.log(appointment?.appointment_date, apptDate);
			// appointment.appointment_date = apptDate;
			setIsLoading(true);
			axios
				.get(
					`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/check-booked-appt-exist/${appointment.consultant["heId"]
					}/${apptDate}/${moment(
						appointment.appointment_time,
						"hh:mm A"
					).format("HH:mm")}/${appointment.consultant["userId"]}/`
				)
				.then((res) => {
					setIsLoading(false);
					// If there is no booked existing appointment for selected slot with selected doctor? - then proceed with payment and appointment creation
					if (res.data) {
						const apiNotes = {
							address: "Razorpay Corporate Office",
							patient_name: patientDetails["first_name"],
							patient_email: patientDetails["email"],
							patient_id: patientDetails["patient_id"],
							patient_mrdno: patientDetails["patient_no"],
							patient_contact: patientDetails["mobile"],
							patient_age: patientDetails["age"],
							patient_gender: patientDetails["gender"],
							doctor_name: appointment.consultant["firstName"],
							doctor_email: appointment.consultant["pdEmail1"],
							doctor_contact: appointment.consultant["pdPhone1"],
							health_establishment_id: appointment.consultant["heId"],
							user_id: appointment.consultant["userId"],
							location_id: appointment.consultant["locId"],
							service: appointment?.service_name,
						};

						let amount = 40000;
						if (appointment?.package_amount && appointment?.package_amount > 0)
							amount = appointment.package_amount * 100;

						const orderPayload = {
							amount: amount,
							currency: "INR",
							receipt: "receipt#1",
							notes: apiNotes,
						};
						setIsLoading(true);
						axios
							.post(
								`${Utils.PRIISM_API_ENDPOINT}/payment-webhooks/create-order/${appointment.consultant["heId"]}/`,
								orderPayload
							)
							.then((order) => {
								setIsLoading(false);
								const orderObj = order.data["order"];
								//const pmtRequestKeys = order.data["paymentHeaders"];
								if (
									orderObj &&
									_.has(orderObj, "id") &&
									orderObj["id"] &&
									orderObj["status"] == "created"
								) {
									const options = {
										key: RazorPayUtils.PAYMENT_CONFIGS[appointment.consultant["heId"]]["apiKey"],
										key_secret: RazorPayUtils.PAYMENT_CONFIGS[appointment.consultant["heId"]]["apiSecret"],
										amount: amount,
										order_id: orderObj.id,
										currency: "INR",
										name: "GNR MANOSHANTI", //your business name
										description: "GNR MANOSHANTI",
										// receipt: "receipt#1",
										theme: {
											color: "#3399cc",
										},
										handler: function (response) {
											// Payment successful
											console.log("Payment successful!");
											console.log("Payment ID:", response.razorpay_payment_id);
											console.log("Order ID:", response.razorpay_order_id);
											console.log("Signature:", response.razorpay_signature);

											toast.success(
												"Your appointment payment was successful. Please await confirmation of your booking.",
												SharedHelpers.toastConfigs
											);
											// You can handle additional actions here, like updating the order status in your database or triggering a "thank you" message to the user.
											bookOrRescheduleAppointment(e, response, orderPayload);
										},
										// Error handler function to handle payment errors
										errorHandler: function (error) {
											console.log("Payment failed with error:", error);

											// You can display an error message to the user or perform other actions to handle the error scenario.
										},
										notes: apiNotes,
										prefill: {
											name: patientDetails["first_name"],
											email: patientDetails["email"],
											contact: patientDetails["mobile"],
										},
									};
									// console.log(appointment.consultant, patientDetails)
									var instance = new Razorpay(options);
									instance.on("payment.failed", function (response) {
										console.log(response.error.code);
										console.log(response.error.description);
										console.log(response.error.source);
										console.log(response.error.step);
										console.log(response.error.reason);
										console.log(response.error.metadata.order_id);
										console.log(response.error.metadata.payment_id);
										toast.error(
											"Payment failed. Please try again or use a different payment method",
											SharedHelpers.toastConfigs
										);
									});
									instance.open();
									e.preventDefault();
								}
							});
					} else {
						toast.warn(
							"The selected time slot is currently unavailable for booking. Please choose a different slot.",
							SharedHelpers.toastConfigs
						);
					}
				})
				.catch((error) => {
					setIsLoading(false);
					console.error(error);
				});
		}
	};

	const bookOrRescheduleAppointment = (e, razorpayResponse, orderPayload) => {
		// console.log(razorpayResponse)
		setIsLoading(true);
		let patientObj = { ...patientDetails };
		let apptDate = moment(appointment?.appointment_date)["_d"];
		let apptObj = {
			apptId: appointment?.apptId,
			doctor: appointment.consultant?.userId,
			heId: appointment.consultant?.heId,
			start: apptDate,
			end: apptDate,
			selectedService: appointment?.selectedService,
			startTime: moment(appointment.appointment_time, "hh:mm A").format(
				"HH:mm"
			),
			clinic: appointment.clinic,
			status: appointment?.status,
			paymentReceived: false,
			paymentProvider: RazorPayUtils.PROVIDER,
			paymentResponse: { ...razorpayResponse },
		};
		let endTime = moment(appointment.appointment_time, "hh:mm A").add(
			appointment.duration,
			"minutes"
		);
		apptObj["endTime"] = moment(endTime).format("HH:mm");
		patientObj["apptDetails"] = { ...apptObj };

		axios
			.post(
				`${Utils.PRIISM_API_ENDPOINT}/appt_bookings/patientappt/${appointment.session_type}/`,
				patientObj
			)
			.then((apptResp) => {
				setIsLoading(false);
				// console.log("apptResp => ", apptResp?.data)
				const paymentObj = {
					apptId: apptResp?.data?.["apptId"],
					patientId: apptResp?.data?.["patientId"],
					patientInfo: apptResp?.data?.["patient"],
					visitId: null,
					discount: null,
					reasonForDiscount: null,
					netAmount: null,
					isVoided: null,
					voidedBy: null,
					voidedDate: null,
					reasonForCancellation: null,
					updatedBy: {
						...SharedHelpers.getUserObject(appointment.consultant),
						locId: appointment.consultant["locId"],
					},
					receiptNo: null,
					dateOfRefund: null,
					refundedAmount: null,
					paymentResponse: { ...razorpayResponse },
					consultationMode: appointment?.session_type,
				};
				setIsLoading(true);
				// console.log("coming here paymentObj", paymentObj)
				//http://192.168.1.11:5000/v1/
				axios
					.post(
						`${Utils.PRIISM_API_ENDPOINT}/payment-webhooks/create-appt-payment/${appointment.consultant?.heId}/`,
						paymentObj
					)
					.then((res) => {
						if (!patientObj?.patient_id || !patientObj.patient_no) {
							patientDetails["patient_no"] =
								apptResp?.data?.["patient"]?.["patientNo"];
							patientDetails["patient_id"] =
								apptResp?.data?.["patient"]?.["patientId"];
							patientDetails["heid"] = apptResp?.data?.["heId"];
						}
						if (
							patientDetails?.["consent"] &&
							patientDetails?.["consent"].length > 0
						) {
							patientDetails["consent"].push({
								updatedDate: moment().toISOString(true),
								agreed: true,
							});
						} else {
							patientDetails["consent"] = [
								{ updatedDate: moment().toISOString(true), agreed: true },
							];
						}
						if (patientDetails?.patient_no) {
							const authToken = localStorage.getItem("authToken");
							console.log(authToken)
							// console.log("patientDetails ", { ...patientDetails })
							setIsLoading(true);

							toast.success(
								"Your appointment is confirmed.",
								SharedHelpers.toastConfigs
							);
							axios
								.put(
									`${Utils.PRIISM_APPT_API_ENDPOINT}/update`,
									{ ...patientDetails },
									{
										headers: {
											Authorization: `Bearer ${authToken}`,
											"Content-Type": "application/json", // Set the appropriate content type for your request
										},
									}
								)
								.then((res) => {
									localStorage.setItem(
										"patientDetails",
										JSON.stringify(res.data)
									);
									window.location.href = "/appointment-list/" + referenceCode;
								});

							setIsLoading(false);
						}
					})
					.catch((error) => {
						setIsLoading(false);
						console.error(error);
					});
			})
			.catch((error) => {
				setIsLoading(false);
				console.error(error);
			});
	};

	const scrollToId = (divId) => {
		const element = document.getElementById(divId);
		element.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest",
		});
	};

	const getStepNo = (stepId) => {
		let index = _.findIndex(steps, ["id", stepId]);
		if (index >= 0) return index;
		else return "";
	};

	const login = (message = "") => {
		// console.log("from login")
		setIsLoading(true);
		axios
			.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/login-with-mobileno-or-email`, {
				email: loginOrRegisterObj?.email ?? "",
				mobile: loginOrRegisterObj?.mobile ?? "",
				mode: "patient",
			})
			.then((res) => {
				setIsLoading(false);
				setpatientDetails(res.data);
				onLoginSuccess(res.data);
				message = message + "  User Authenticated Successfully";
				setLoginErrorMessage(message);
			})
			.catch((error) => {
				setIsLoading(false);
				message = "Error in sign in. Please try again later.";
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message
				) {
					message = error.response.data.message;
				}
				setLoginErrorMessage(message);
			});
	};

	const createPatientRecordAndLogin = () => {
		// console.log("from createPatientRecordAndLogin")
		setIsLoading(true);
		const payload = {
			first_name: loginOrRegisterObj?.first_name ?? "",
			mobile: loginOrRegisterObj?.mobile ?? "",
			email: loginOrRegisterObj?.email ?? "",
			sex: loginOrRegisterObj?.sex ?? "",
			age: loginOrRegisterObj?.age ?? "",
		};
		const obj = {
			"patient": payload,
			"user": {
				"locId": appointment.consultant.locId,
				"userId": appointment.consultant.userId,
				"heId": appointment.consultant.heId
			}
		};
		axios
			.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/signup-with-mobileno`, obj)
			.then((res) => {
				setIsLoading(false);
				login("User Account Created Successully.");
			})
			.catch((error) => {
				let message = "Error in sign up. Please try again later.";
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.message
				) {
					console.log("error", error);
					message = error.response.data.message;
				}
				setIsLoading(false);
				setLoginErrorMessage(message);
			});
	};

	const requestOTP = (send_via = "SMS", purpose = "LOGIN") => {
		if (!loginOrRegisterObj?.email && !loginOrRegisterObj?.mobile) {
			alert("Please Enter Email or Mobile No to send OTP.");
			return false;
		}
		let payload = {
			email: loginOrRegisterObj?.email ?? "",
			mobile: loginOrRegisterObj?.mobile ?? "",
			send_otp_through: send_via, //"EMAIL_AND_SMS", "SMS", "EMAIL"
			status: "REQUESTED",
			purpose: purpose,
		};
		setIsLoading(true);
		axios
			.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/generate-and-send-otp`, payload)
			.then((res) => {
				setIsLoading(false);
				if (
					res.data.statusCode == 200 &&
					(!_.has(res.data, "hasAccount") || res.data?.hasAccount != false)
				)
					setStatus("REQUESTED");
				if (res.data?.hasAccount) {
					setHaveAccount(res.data.hasAccount);
					setLoginOrRegisterObj({
						mobile: loginOrRegisterObj?.mobile ?? null,
						email: loginOrRegisterObj?.email ?? null,
					});
				} else if (_.has(res.data, "hasAccount")) {
					setLoginErrorMessage(res.data?.message);
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const resendOtp = (send_via = "SMS", purpose = "LOGIN") => {
		requestOTP(send_via, purpose);
	};

	const renderTime = ({ remainingTime }) => {
		if (remainingTime === 0) {
			alert("OTP has timed out, please click on 'Request New OTP' button");
			setStatus("TIMED_OUT");
			setLoginOrRegisterObj({ ...loginOrRegisterObj, otp: "" });
		}
		return (
			<div className="timer">
				<div className="value">{remainingTime}</div>
			</div>
		);
	};

	const validateOTP = (send_via = "SMS", purpose = "LOGIN") => {
		if (!loginOrRegisterObj?.email && !loginOrRegisterObj?.mobile) {
			alert("Please Enter Email or Mobile No to send OTP.");
			return false;
		}
		let payload = {
			email: loginOrRegisterObj.email ?? "",
			mobile: loginOrRegisterObj.mobile ?? "",
			otp: loginOrRegisterObj.otp ?? "",
			status: status,
			purpose: purpose,
			send_otp_through: send_via,
		};
		setIsLoading(true);
		axios
			.post(`${Utils.PRIISM_APPT_API_ENDPOINT}/validate-otp`, payload)
			.then((res) => {
				setIsLoading(false);
				setStatus(res.data.status);
				if (res.data.statusCode == 200) {
					if (res.data.status == "VERIFIED") {
						if (!haveAccount) createPatientRecordAndLogin();
						if (haveAccount) login("");
					}
				}
			})
			.catch((error) => {
				setIsLoading(false);
				// if (res.data.statusCode)
				//     setStatus(res.status);
			});
	};
	const openConsentModal = () => {
		setIsConsentModalOpen(true);
	};
	const closeConsentModal = () => {
		setIsConsentModalOpen(false);
	};

	const consentModal = () => {
		return (
			<Dialog
				open={isConsentModalOpen}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth={true}
				maxWidth={"lg"}
			>
				<DialogTitle id="alert-dialog-title" align="center">
					{"CONSENT FOR CONSULTATION/THERAPY"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{SharedHelpers.CONSENT}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => closeConsentModal()}>
						Close
					</Button>
					{!appointment?.consent && (
						<Button
							variant="contained"
							onClick={() => {
								setAppointment({ ...appointment, consent: true });
								// Update Consent here
								closeConsentModal();
							}}
							autoFocus
						>
							I Agree
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<ThemeProvider theme={customTheme}>
			{consentModal()}
			{isLoading && <Spinner />}

			<div className="book-appointment-page">
				<div className="provider-section">
					{appointment?.consultant?.userId && (
						<>
							{/* {patientDetails?.patient_no && */}
							<Fab
								size="small"
								aria-label="back"
								onClick={(e) => {
									history.back();
								}}
								style={{ backgroundColor: "#d079e9" }}
							>
								<ArrowBackIcon />
							</Fab>
							{/* } */}
							<div className="provider-card">
								<img
									className="provider-photo"
									src={
										appointment?.consultant?.profilePicture
											? Utils.PRIISM_WEB_URL +
											appointment.consultant.profilePicture
											: "/images/default_user.png"
									}
									alt="Provider Photo"
								/>
								<div className="details-div">
									<span className="name-sec">
										{appointment?.consultant?.firstName}&nbsp;
										{appointment?.consultant?.lastName
											? " " + appointment?.consultant?.lastName
											: ""}
									</span>
									{appointment?.consultant?.medicalCouncilName2 && (
										<span className="details-sub-section hide-in-mobile">
											Reg. No.: {appointment?.consultant?.medicalCouncilName2}
										</span>
									)}
									{(appointment?.consultant?.role ||
										appointment?.consultant?.qualification) && (
											<span
												style={{
													fontSize: "0.75rem",
													opacity: "0.6",
													color: "#4C4C4C",
													textDecoration: "none",
													spacing: "normal",
													cursor: "unset",
												}}
												className="provider-experience hide-in-mobile"
											>
												{appointment?.consultant?.role}
												<span className="dot"></span>
												{appointment?.consultant?.qualification}
											</span>
										)}
								</div>
								<div
									className="provider-card-right-arrow"
									onClick={() => {
										window.location.href =
											"/profile/" +
											referenceCode +
											"/" +
											appointment?.consultant?.userId;
									}}
								>
									{/* <ChevronRightIcon style={{
                                overflowClipMargin: 'content-box', overflow: 'clip'
                            }} /> */}
									<img src="/images/chevron-right.svg"></img>
								</div>
							</div>
						</>
					)}
					<div className="booking-stepper hide-in-mobile">
						<Stepper activeStep={activeStep} orientation="vertical">
							{steps.map((step, index) => (
								<Step key={step.label}>
									<StepLabel
										// optional={
										//     index === 2 ? (
										//         <Typography variant="caption">Last step</Typography>
										//     ) : null
										// }
										onClick={(e) => {
											if (index <= activeStep) scrollToId(step.id);
										}}
									>
										{step.label}
									</StepLabel>
									{/* <StepContent>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent> */}
								</Step>
							))}
						</Stepper>
					</div>
				</div>
				<div className="hide-scroll-bar right-section">
					<div className="booking-container" id="booking-container">
						<div className="booking-container-head">
							<h3 className="heading-one">Book Your Session</h3>
							<h5 className="sub-heading">
								Book an appointment to connect with a mental health expert
							</h5>
						</div>
						<div className="section" id="staff-selection">
							<div className="section-title">
								<span>
									{getStepNo(staffSelection["id"]) + 1}. Select consultant
								</span>
							</div>
							<div className="section-body">
								<Autocomplete
									className="auto-complete"
									disabled={appointment?.apptId}
									value={appointment?.consultant ? appointment.consultant : ""}
									onChange={(event, newValue) => {
										handleStaffChange(newValue);
									}}
									id="free-solo-with-text-demo"
									options={doctors}
									getOptionLabel={(option) => {
										let name = "";
										// Value selected with enter, right from the input
										if (typeof option === "string") {
											name = option;
										} else {
											name = option.firstName;
											if (option?.lastName) name += " " + option.lastName;
											if (option.location && option.location["locId"]) {
												name += " (" + option.location["name"] + ")";
											}
											if (option.prefix) {
												let prefix = option.prefix.endsWith(".")
													? option.prefix
													: option.prefix + ".";
												name = prefix + " " + name;
											}
										}
										return name;
									}}
									// renderOption={(option) => option.diagnosis_name}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Search Staff"
											variant={"standard"}
											inputProps={{
												...params.inputProps,
											}}
										/>
									)}
								/>
							</div>
						</div>
						<hr className="section-divider"></hr>
						<div className="section">
							<div className="section-title">
								<span>
									{getStepNo(sessionType["id"]) + 1}. Select session type
								</span>
							</div>
							<div className="section-body">
								<ToggleButtonGroup
									// color="primary"
									value={appointment?.session_type}
									exclusive
									onChange={handleChange}
									aria-label="session_type"
									className="toggle-buttons"
									disabled={appointment?.apptId}
								>
									{sessionTypes?.map((type, index) => (
										<ToggleButton
											key={type}
											className="session-type-btns"
											value={type}
											style={{
												borderRadius:
													index === 0
														? sessionTypes?.length - 1 === index
															? "2rem"
															: "2rem 0 0 2rem"
														: sessionTypes?.length - 1 === index
															? "0 2rem 2rem 0"
															: "0 0 0 0",
												borderRight: "0px !important",
											}}
										>
											<AddHomeIcon />
											&nbsp; {type}
										</ToggleButton>
									))}
									{/* <ToggleButton className="session-type-btns" value="In-person" style={{ borderRadius: '0.5rem 0 0 0.5rem', borderRight: '0px !important' }}>
                                        <AddHomeIcon />
                                        &nbsp; In-person
                                    </ToggleButton>
                                    <ToggleButton className="session-type-btns" value="Online" style={{ borderRadius: '0 0.5rem 0.5rem 0', borderLeft: '0px !important' }}>
                                        <PhoneIphoneIcon />
                                        &nbsp; Online
                                    </ToggleButton> */}
								</ToggleButtonGroup>
							</div>
						</div>
						{appointment.session_type !== "Online" && (
							<hr className="section-divider"></hr>
						)}
						{appointment.session_type !== "Online" && (
							<div className="section" id="clinic-cards-section">
								<div className="section-title">
									<span>{getStepNo(clinicStep["id"]) + 1}. Select clinic</span>
								</div>
								<div className="clinic-cards-section">
									<Grid
										container
										justifyContent="space-evenly"
										spacing={{ xs: 2, md: 3 }}
										columns={{ xs: 4, sm: 8, md: 12 }}
										style={{ marginLeft: "-2.2rem" }}
									>
										{locations &&
											locations.length > 0 &&
											locations.map((element) => (
												<Grid
													item
													xs={12}
													sm={12}
													md={locations.length === 1 ? 12 : 6}
													key={element.locId}
												>
													<div
														className={
															appointment?.clinic === element.locId
																? "clinic-card clinic-selected"
																: "clinic-card"
														}
														onClick={(e) => hadleClinicChange(e, element.locId)}
													>
														<img
															className="clinic-photo"
															src="/images/manoshanti-pune.jpg"
															alt="Provider Photo"
														/>
														<div
															className="clinic-details"
															style={{ width: "100%" }}
														>
															{/* <div className="name-sec">
                                                            Dr Divya G Nallur
                                                        </div><br />
                                                        <div className="details-sub-section">
                                                            Reg. No.: 57265
                                                        </div><br />
                                                        <span style={{ fontSize: "0.75rem", opacity: "0.6", textDecoration: "none", spacing: "normal", cursor: "unset" }}>
                                                            17 yrs exp<br />
                                                            <span className="dot"></span>
                                                            M.B.B.S, FRCPsych, CCT, M.Sc, PGDP
                                                        </span> */}
															<span className="name-sec">{element.name}</span>
															<br />
															{/* <span className="details-sub-section">
                                                            Psychiatry and Psychotherapy Clinic
                                                        </span><br /> */}
															<span
																style={{
																	fontSize: "0.9rem",
																	opacity: "1",
																	textDecoration: "none",
																	spacing: "normal",
																	cursor: "unset",
																}}
															>
																{element?.address}
																<br />
																<span className="dot"></span>
																{element?.city ? element?.city : ""}{" "}
																{element?.pin
																	? (element?.city ? " - " : "") + element?.pin
																	: ""}
																.<br />
																{/* Landmark
                                                            <span className="dot"></span> */}
																{/* Near Ganaraj Mangal Karyalay & Behind KFC */}
																{/* <span className="dot"></span>
                                                            Pune */}
																{element?.phone1 && (
																	<span>
																		<Call
																			style={{
																				fontSize: "15px",
																				marginBottom: "-4px",
																			}}
																		/>{" "}
																		{element?.phone1}
																	</span>
																)}
															</span>
														</div>
													</div>
												</Grid>
											))}
										{/* <Grid item xs={12} sm={12} md={6}>
                                        <div className={appointment?.clinic === 'clinic2' ? "clinic-card clinic-selected" : "clinic-card"} onClick={(e) => hadleClinicChange(e, 'clinic2')}>
                                            <img className="clinic-photo" src="/images/manoshanti-pune.jpg" alt="Provider Photo" />
                                            <div className="clinic-details" style={{ width: '100%' }}>
                                                <span className="name-sec">
                                                    Manoshanti
                                                </span><br />
                                                <span className="details-sub-section">
                                                    Psychiatry and Psychotherapy Clinic
                                                </span><br />
                                                <span style={{ fontSize: "0.75rem", opacity: "0.6", textDecoration: "none", spacing: "normal", cursor: "unset" }}>
                                                    Baner, Pune<br />
                                                    <span className="dot"></span>
                                                    101, The Square, 113/9, Baner Mahalunge Road<br />
                                                    Landmark
                                                    <span className="dot"></span>
                                                    Near Ganaraj Mangal Karyalay & Behind KFC
                                                    <span className="dot"></span>
                                                    Pune
                                                </span>
                                            </div>
                                        </div>
                                    </Grid> */}
									</Grid>
								</div>
							</div>
						)}
						<hr className="section-divider"></hr>
						<div className="section" id="duration-section">
							<div className="section-title">
								<span>
									{getStepNo(sessionDuration["id"]) + 1}. Select session
									duration
								</span>
							</div>
							<div className="section-body">
								<div className="duration-section">
									{durations.map((service, index) => (
										<div
											key={service.serviceId}
											className={
												appointment?.service_name === service.name
													? "duration-element duration-selected"
													: "duration-element"
											}
											onClick={(e) => {
												handleDurationChange(e, service);
											}}
										>
											<div className="provider-info">
												<span className="duration-mins">{service.name}</span>
												<div>
													Starts @{" "}
													<b>
														<span className="fee">
															&#x20B9;{service.default_bill_amount} for{" "}
															{service.duration}
														</span>
													</b>{" "}
													mins
												</div>
											</div>
										</div>
									))}
									{/* <div className={(appointment?.duration === 60) ? "duration-element duration-selected" : "duration-element"} onClick={(e) => { handleDurationChange(e, 60) }}>
                                        <span className="duration-mins">60</span>
                                        <span className="duration-min-text">min</span>
                                    </div> */}
								</div>
								{/* <div className="session-package">
                                    <div className="session-package-element" onClick={(e) => { handlePackageChange(e, 1, 4000) }}>
                                        <div className="session-package-slot">
                                            <div className="session-package-details">
                                                <span className="session-plans">
                                                    <span className="package-name">Get a single session</span>
                                                    1 session
                                                </span>
                                            </div>
                                            <div className="session-package-price">
                                                <div style={{ width: '10rem', textAlign: 'right' }}>
                                                    <span style={{ fontSize: '0.9rem', color: '#4c4c4c', textDecoration: 'none', opacity: '1', spacing: 'normal', cursor: 'unset' }}>
                                                        ₹4000/session
                                                    </span>
                                                </div>
                                                <div className="session-package-radio">
                                                    {(appointment?.package === 1) ?
                                                        <RadioButtonCheckedIcon /> :
                                                        <RadioButtonUncheckedIcon />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="session-package-element" onClick={(e) => { handlePackageChange(e, 6, 24000) }}>
                                        <div className="session-package-slot">
                                            <div className="session-package-details">
                                                <span className="session-plans">
                                                    <span className="package-name">Get a package</span>
                                                    6 sessions + 1 free
                                                </span>
                                            </div>
                                            <div className="session-package-price">
                                                <div style={{ width: '10rem', textAlign: 'right' }}>
                                                    <span style={{ fontSize: '0.9rem', color: '#4c4c4c', textDecoration: 'none', opacity: '1', spacing: 'normal', cursor: 'unset' }}>
                                                        ₹24000/7 sessions
                                                    </span>
                                                </div>
                                                <div className="session-package-radio">
                                                    {(appointment?.package === 6) ?
                                                        <RadioButtonCheckedIcon /> :
                                                        <RadioButtonUncheckedIcon />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="session-package-element" onClick={(e) => { handlePackageChange(e, 10, 40000) }}>
                                        <div className="session-package-slot">
                                            <div className="session-package-details">
                                                <span className="session-plans">
                                                    <span className="package-name">Get a package</span>
                                                    10 sessions + 2 free
                                                </span>
                                            </div>
                                            <div className="session-package-price">
                                                <div style={{ width: '10rem', textAlign: 'right' }}>
                                                    <span style={{ fontSize: '0.9rem', color: '#4c4c4c', textDecoration: 'none', opacity: '1', spacing: 'normal', cursor: 'unset' }}>
                                                        ₹40000/12 session
                                                    </span>
                                                </div>
                                                <div className="session-package-radio">
                                                    {(appointment?.package === 10) ?
                                                        <RadioButtonCheckedIcon /> :
                                                        <RadioButtonUncheckedIcon />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
							</div>
						</div>

						{appointment?.consultant?.displayMessage && (
							<React.Fragment>
								<hr className="section-divider"></hr>
								<div className="booking-container-head">
									<div
										style={{
											padding: "10px 25px 10px 25px",
											borderRadius: "2px",
											backgroundColor: "rgb(255, 255, 255)",
											textAlign: "center",
											margin: "5px 0px",
										}}
									>
										<div
											className="section-title"
											style={{ fontSize: "1.2rem" }}
										>
											<span>Consultant Availability</span>
										</div>
										<h5 className="sub-heading">
											<div
												dangerouslySetInnerHTML={{
													__html: appointment?.consultant?.displayMessage,
												}}
											/>
										</h5>
									</div>
								</div>
							</React.Fragment>
						)}
						<hr className="section-divider"></hr>
						<div className="section" id="time-slots">
							<div className="section-title">
								<span>
									{getStepNo(slotSelection["id"]) + 1}. Choose your slot
								</span>
							</div>
							<div className="section-body" style={{ padding: "0px 50px", minWidth: appointment?.appointment_date ? "70vw" : "50vw" }}>
								<Grid
									container
									spacing={2}
									justifyContent="center"
									style={{ backgroundColor: "#fff" }}
									alignItems="center"
								>
									<Grid item xs={12} sm={12} md={6}>
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<StaticDatePicker
												label="Date"
												value={appointment?.appointment_date}
												onChange={(newValue) => {
													handleApptDateChange(newValue);
												}}
												renderInput={(params) => <TextField {...params} />}
												openTo="day"
												hideToolbar
												disablePast
												displayStaticWrapperAs="desktop"
												inputFormat="MM/dd/yyyy"
												adapter={AdapterMoment}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={6}
										style={{ alignSelf: "baseline" }}
									>
										<Box style={{ marginTop: "-2px" }}>
											<Grid
												container
												spacing={2}
												justifyContent={"center"}
												sx={{
													borderBottom: 2,
													borderColor: "divider",
													margin: "10px -20px",
												}}
											>
												<Grid item>
													<Typography
														style={{ fontWeight: "bold" }}
														color="#d079e9"
													>
														TIME SLOTS{" "}
													</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={2}>
												{appointment?.appointment_date &&
													apptSlots && appointment?.selectedService?.serviceId &&
													_.has(apptSlots, appointment?.selectedService?.serviceId) &&
													apptSlots?.[appointment?.selectedService?.serviceId]?.length > 0 &&
													apptSlots?.[appointment?.selectedService?.serviceId]?.map((slot) => (
														<Grid item xs={6} sm={4} md={4} lg={3} key={slot}>
															<Chip
																label={moment(slot?.start, 'HH:mm').format("hh:mm A")}
																variant={(slot?.isBooked || slot?.isBlocked) ? 'filled' : 'outlined'}
																// variant={
																// 	(!checkSlotIsAvaialble(slot) || !checkIsSlotBlocked(slot)) &&
																// 		!checkIsSlotBooked(
																// 			slot,
																// 			true,
																// 			apptSlots.morning
																// 		) &&
																// 		appointment?.appointment_time !== slot
																// 		? "outlined"
																// 		: "filled"
																// }
																disabled={(slot?.isBooked || slot?.isBlocked) ? true : false}
																// disabled={
																// 	checkSlotIsAvaialble(slot) || checkIsSlotBlocked(slot) ||
																// 	checkIsSlotBooked(
																// 		slot,
																// 		true,
																// 		apptSlots.morning
																// 	)
																// }
																color={slot?.isBooked ? "primary" : slot?.isBlocked ? "default" : (appointment?.appointment_time === slot?.start) ? "success" : "primary"}
																// color={
																// 	checkIsSlotBooked(slot, true, apptSlots)
																// 		? "primary"
																// 		: (checkSlotIsAvaialble(slot) || checkIsSlotBlocked(slot))
																// 			? "default"
																// 			: appointment?.appointment_time === slot
																// 				? "success"
																// 				: "primary"
																// }
																onClick={(e) => {
																	handleApptTimeSoltChange(e, slot?.start);
																}}
															/>
														</Grid>
													))}
												{apptSlots?.length <= 0 && (
													<Grid item xs={12}>
														<Typography component={"h6"}>
															{noSlotMessage}
														</Typography>
													</Grid>
												)}
											</Grid>
										</Box>
									</Grid>
								</Grid>
							</div>
						</div>
						<hr className="section-divider"></hr>
						{(!patientDetails || !patientDetails["id"]) &&
							activeStep === getStepNo(signupStep["id"]) && (
								<div className="section">
									<div className="signup-section">
										<div className="signup-header">
											<h3 className="signup-head-one">
												{getStepNo(signupStep["id"]) + 1}.{" "}
												{haveAccount ? "Login" : "Sign Up"}
											</h3>
											{!haveAccount && (
												<span className="signup-login-link">
													Already have PRIISM account?&nbsp;
													<a
														className="login-link"
														onClick={() => {
															if (
																status !== "RE-REQUESTED" &&
																status !== "REQUESTED"
															) {
																setLoginErrorMessage("");
																setLoginOrRegisterObj({});
																setHaveAccount(true);
																setStatus("");
															} else {
																alert(
																	"An OTP has been sent to both your registered email and the mobile number you provided. Please enter the OTP to proceed."
																);
															}
														}}
													>
														Login{" "}
													</a>
												</span>
											)}
											{haveAccount && (
												<span className="signup-login-link">
													Don't have PRIISM account?&nbsp;
													<a
														className="login-link"
														onClick={() => {
															if (
																status !== "RE-REQUESTED" &&
																status !== "REQUESTED"
															) {
																setLoginErrorMessage("");
																setLoginOrRegisterObj({});
																setHaveAccount(false);
																setStatus("");
															} else {
																alert(
																	"An OTP has been sent to both your registered email and the mobile number you provided. Please enter the OTP to proceed."
																);
															}
														}}
													>
														Sign Up{" "}
													</a>
												</span>
											)}
										</div>
										<h5 className="email-signup-head">
											{haveAccount
												? "Login with mobile number to continue"
												: "Sign up with your mobile number"}
										</h5>
										<div className="signup-form">
											<div className="signup-form-element">
												<Grid
													container
													spacing={2}
													justifyContent="center"
													alignItems="center"
												>
													{haveAccount && (
														<>
															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	fullWidth
																	required
																	id="outlined-error-helper-text"
																	label="Mobile Number"
																	variant="outlined"
																	color="secondary"
																	name="mobile"
																	autoComplete="off"
																	defaultValue={loginOrRegisterObj?.mobile ?? ""}
																	helperText={
																		_.has(validations, "mobile") &&
																			!validations?.mobile
																			? "Invalid Mobile Number"
																			: ""
																	}
																	error={
																		_.has(validations, "mobile") &&
																			!validations?.mobile
																			? true
																			: false
																	}
																	onChange={(e) => {
																		setLoginOrRegisterObj({
																			...loginOrRegisterObj,
																			[e.target.name]: e.target.value,
																			'email': ''
																		});
																		// Regular expression to validate a 10-digit Indian mobile number
																		const mobileNumberRegex = /^[6-9]\d{9}$/;
																		setValidations({
																			...validations,
																			[e.target.name]: mobileNumberRegex.test(
																				e.target.value
																			),
																		});
																	}}
																/>
															</Grid>
															<Grid item xs={12}>
																<Divider>
																	<Chip label="or" size="small" />
																</Divider>
															</Grid>
															<Grid item xs={12}>
																<TextField
																	margin="normal"
																	required
																	fullWidth
																	id="email"
																	label="Email"
																	name="email"
																	variant="outlined"
																	color="secondary"
																	autoComplete="off"
																	value={loginOrRegisterObj?.email ?? ''}
																	helperText={(_.has(validations, 'email') && !validations?.email) ? "Invalid Email" : ""}
																	error={(_.has(validations, 'email') && !validations?.email) ? true : false}
																	onChange={(e) => {
																		setLoginOrRegisterObj({
																			...loginOrRegisterObj, [e.target.name]: e.target.value,
																			'mobile': ''
																		});
																		// Regular expression to validate email addresses
																		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
																		setValidations({ ...validations, [e.target.name]: emailRegex.test(e.target.value) });
																	}}
																/>
															</Grid>
														</>
													)}
													{!haveAccount && (
														<React.Fragment>
															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	fullWidth
																	required
																	id="outlined-error-helper-text"
																	label="Name"
																	// defaultValue=""
																	helperText={
																		_.has(validations, "first_name") &&
																			!validations?.first_name
																			? "Invalid Name"
																			: ""
																	}
																	error={
																		_.has(validations, "first_name") &&
																			!validations?.first_name
																			? true
																			: false
																	}
																	onChange={(e) => {
																		setLoginOrRegisterObj({
																			...loginOrRegisterObj,
																			[e.target.name]: e.target.value,
																		});
																		// Regular expression to validate names with alphabets and spaces
																		const nameRegex = /^[a-zA-Z\s]+$/;
																		setValidations({
																			...validations,
																			[e.target.name]: nameRegex.test(
																				e.target.value
																			),
																		});
																		setStatus("");
																	}}
																	variant="outlined"
																	color="secondary"
																	name="first_name"
																/>
															</Grid>

															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	fullWidth
																	required
																	id="outlined-error-helper-text"
																	label="Age"
																	type="number"
																	// defaultValue=""
																	helperText={
																		_.has(validations, "age") &&
																			!validations?.age
																			? "Invalid Age"
																			: ""
																	}
																	error={
																		_.has(validations, "age") &&
																			!validations?.age
																			? true
																			: false
																	}
																	onChange={(e) => {
																		setLoginOrRegisterObj({
																			...loginOrRegisterObj,
																			[e.target.name]: e.target.value,
																		});
																		// Regular expression to validate names with alphabets and spaces
																		const ageRegex =
																			/^(?:1[0-4]\d|150|[1-9]\d?)$/;
																		setValidations({
																			...validations,
																			[e.target.name]: ageRegex.test(
																				e.target.value
																			),
																		});
																		setStatus("");
																	}}
																	variant="outlined"
																	color="secondary"
																	name="age"
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<FormControl fullWidth>
																	<FormLabel
																		style={{ textAlign: "left" }}
																		id="demo-row-radio-buttons-group-label"
																	>
																		Gender
																	</FormLabel>
																	<RadioGroup
																		row
																		aria-labelledby="demo-row-radio-buttons-group-label"
																		name="sex"
																		onChange={(e) => {
																			setLoginOrRegisterObj({
																				...loginOrRegisterObj,
																				[e.target.name]: e.target.value,
																			});
																		}}
																	>
																		<FormControlLabel
																			value="F"
																			control={<Radio />}
																			label="Female"
																		/>
																		<FormControlLabel
																			value="M"
																			control={<Radio />}
																			label="Male"
																		/>
																		<FormControlLabel
																			value="O"
																			control={<Radio />}
																			label="Other"
																		/>
																	</RadioGroup>
																</FormControl>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	fullWidth
																	id="outlined-error-helper-text"
																	label="Email Address"
																	// defaultValue=""
																	// helperText="Incorrect entry."
																	variant="outlined"
																	color="secondary"
																	name="email"
																	helperText={
																		_.has(validations, "email") &&
																			!validations?.email
																			? "Invalid Email"
																			: ""
																	}
																	error={
																		_.has(validations, "email") &&
																			!validations?.email
																			? true
																			: false
																	}
																	required
																	onChange={(e) => {
																		setLoginOrRegisterObj({
																			...loginOrRegisterObj,
																			[e.target.name]: e.target.value,
																		});
																		// Regular expression to validate email addresses
																		const emailRegex =
																			/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
																		setValidations({
																			...validations,
																			[e.target.name]: emailRegex.test(
																				e.target.value
																			),
																		});
																		setStatus("");
																	}}
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	helperText={
																		_.has(validations, "mobile") &&
																			!validations?.mobile
																			? "Invalid Mobile Number"
																			: ""
																	}
																	error={
																		_.has(validations, "mobile") &&
																			!validations?.mobile
																			? true
																			: false
																	}
																	fullWidth
																	required
																	id="outlined-error-helper-text"
																	label="Mobile Number"
																	// defaultValue=""
																	variant="outlined"
																	color="secondary"
																	name="mobile"
																	autoComplete="off"
																	onChange={(e) => {
																		setLoginOrRegisterObj({
																			...loginOrRegisterObj,
																			[e.target.name]: e.target.value,
																		});
																		// Regular expression to validate a 10-digit Indian mobile number
																		const mobileNumberRegex = /^[6-9]\d{9}$/;
																		setValidations({
																			...validations,
																			[e.target.name]: mobileNumberRegex.test(
																				e.target.value
																			),
																		});
																		setStatus("");
																	}}
																/>
															</Grid>
														</React.Fragment>
													)}

													{((loginOrRegisterObj?.email &&
														loginOrRegisterObj?.mobile &&
														!haveAccount) ||
														(haveAccount && (loginOrRegisterObj?.mobile || loginOrRegisterObj?.email))) && (
															<React.Fragment>
																<Grid item xs={12} sm={12} md={6}>
																	{!status && (
																		<button
																			className="create-appointment-btn"
																			onClick={() => {
																				requestOTP(
																					"EMAIL_AND_SMS",
																					!haveAccount ? "SIGN-UP" : "LOGIN"
																				);
																			}}
																			disabled={
																				(haveAccount && (!validations?.email) && (!validations?.mobile)) ||
																					(!haveAccount &&
																						(!validations?.first_name ||
																							!validations?.mobile ||
																							!validations?.email))
																					? true
																					: false
																			}
																		>
																			Request OTP
																		</button>
																	)}
																	{status === "TIMED_OUT" && (
																		<button
																			className="create-appointment-btn"
																			onClick={() => {
																				resendOtp(
																					"EMAIL_AND_SMS",
																					!haveAccount ? "SIGN-UP" : "LOGIN"
																				);
																			}}
																		>
																			Request New OTP
																		</button>
																	)}
																</Grid>

																{status === "REQUESTED" && (
																	<Alert severity="info">
																		An OTP has been sent to both your registered
																		email and the mobile number you provided.
																	</Alert>
																)}

																{(status === "REQUESTED" ||
																	status === "RE-REQUESTED" ||
																	status === "INCORRECT-OTP" ||
																	status === "TIMED_OUT") && (
																		<Grid item xs={12} sm={12} md={8} lg={10}>
																			<TextField
																				helperText={
																					_.has(validations, "otp") &&
																						!validations?.otp
																						? "Invalid OTP"
																						: ""
																				}
																				error={
																					_.has(validations, "otp") &&
																						!validations?.otp
																						? true
																						: false
																				}
																				fullWidth
																				required
																				id="outlined-error-helper-text"
																				label="OTP"
																				// defaultValue=""
																				variant="outlined"
																				color="secondary"
																				name="otp"
																				autoComplete="off"
																				onChange={(e) => {
																					setLoginOrRegisterObj({
																						...loginOrRegisterObj,
																						[e.target.name]: e.target.value,
																					});
																					// Regular expression to validate a 5-digit OTP
																					const otpRegex = /^\d{5}$/;
																					setValidations({
																						...validations,
																						[e.target.name]: otpRegex.test(
																							e.target.value
																						),
																					});
																				}}
																			/>
																		</Grid>
																	)}

																{(status === "REQUESTED" ||
																	status === "RE-REQUESTED" ||
																	status === "INCORRECT-OTP") && (
																		<Grid item xs={12} sm={12} md={4} lg={2}>
																			<CountdownCircleTimer
																				isPlaying
																				duration={600}
																				size={50}
																				colors={[
																					["#004777", 0.33],
																					["#F7B801", 0.33],
																					["#A30000", 0.33],
																				]}
																				onComplete={() => [true, 1000]}
																			>
																				{renderTime}
																			</CountdownCircleTimer>
																		</Grid>
																	)}
																{(status === "REQUESTED" ||
																	status === "RE-REQUESTED" ||
																	status === "INCORRECT-OTP") && (
																		<Grid
																			item
																			xs={12}
																			sm={12}
																			md={12}
																			style={{ float: "left", height: "45px" }}
																		>
																			<button
																				className="create-appointment-btn"
																				onClick={() => {
																					validateOTP(
																						"EMAIL",
																						!haveAccount ? "SIGN-UP" : "LOGIN"
																					);
																				}}
																				disabled={status === "TIMED_OUT"}
																			>
																				Verify
																			</button>
																		</Grid>
																	)}
																{status === "VERIFIED" && (
																	<Alert severity="info">
																		OTP Validated Successfully
																	</Alert>
																)}
																{status === "INCORRECT-OTP" && (
																	<Alert
																		severity="warning"
																		style={{ marginTop: "15px" }}
																	>
																		Incorrect OTP! please try again.
																	</Alert>
																)}
															</React.Fragment>
														)}
												</Grid>
											</div>
										</div>
										<div className="signup-form-footer">
											<Collapse
												in={loginErrorMessage ? true : false}
												style={{ marginTop: "10px" }}
											>
												<Alert severity="error">{loginErrorMessage}</Alert>
											</Collapse>
										</div>
									</div>
								</div>
							)}
						{patientDetails?.id && loginErrorMessage && (
							<Collapse in={loginErrorMessage ? true : false}>
								<Alert severity="success">{loginErrorMessage}</Alert>
							</Collapse>
						)}
						<hr className="section-divider"></hr>
						{(activeStep === getStepNo(signupStep["id"]) ||
							activeStep === getStepNo(confirmation["id"])) && (
								<div className="section" id="confirm-section">
									<div className="section-title">
										<span>{getStepNo(confirmation["id"]) + 1}. Confirmation</span>
									</div>
									<div className="section-body confirm-body">
										<div className="confirm-session-details">
											<div className="confirm-session-details-divisions">
												<div className="confirm-session-details-division-one">
													{appointment.session_type === "Online" && (
														<VideocamOutlinedIcon
															fontSize="large"
															style={{ color: "#d079e9" }}
														/>
													)}
													{appointment.session_type === "In-person" && (
														<DirectionsWalkIcon
															fontSize="large"
															style={{ color: "#d079e9" }}
														/>
													)}
													<div className="date-and-time">
														<h5 className="date">
															{appointment?.appointment_date
																? moment(appointment.appointment_date).format(
																	"DD MMM, YYYY"
																)
																: ""}
															{/* 09 May, 2023 */}
														</h5>
														{/* <h5 className="time" style={{ textAlign: 'right' }}>
                                                    11:15 AM | 50 mins
                                                </h5> */}
													</div>
												</div>
												<h5 className="time-duration">
													{appointment.appointment_time} &nbsp; | &nbsp;{" "}
													{appointment.duration}
													{/* mins */}
													{/* 11:15 AM | 50 mins */}
												</h5>
											</div>
										</div>
										<div className="payment-details">
											<div className="payment-details-body">
												<h5 className="payment-details-title">Payment Details</h5>
												<div className="payment-details-preview">
													<div className="payment-details-preview-divisions">
														<span className="payment-details-preview-package">
															Price for {appointment?.package} session
															{appointment?.package && appointment.package != "1"
																? "s"
																: ""}
														</span>
														<span className="payment-details-preview-price">
															₹{" "}
															{appointment?.package_amount
																? appointment.package_amount.toFixed(2)
																: ""}
														</span>
													</div>
												</div>
												<div className="final-amount-row">
													<h5 className="final-amount-label">Final amount</h5>
													<h5 className="final-amount">
														₹{" "}
														{appointment?.package_amount
															? appointment.package_amount.toFixed(2)
															: ""}
													</h5>
												</div>
											</div>
										</div>
									</div>
									<div>
										<Grid container mb={2}>
											<Grid item sc={12} sm={12} md={12}>
												<FormControlLabel
													control={
														<Checkbox
															name="consent"
															color="primary"
															checked={appointment?.consent ? true : false}
															onChange={(e) => {
																const { checked, name, value } = e.target;
																appointment[name] = checked ? true : false;
																setAppointment({
																	...appointment,
																	[name]: checked ? true : false,
																});
															}}
															required
														/>
													}
													label={
														<>
															I acknowledge that I have read and agree to the{" "}
															<Link onClick={openConsentModal}>
																terms and conditions
															</Link>
														</>
													}
												/>
											</Grid>
										</Grid>
									</div>

									<div>
										{!appointment?.apptId && (
											<button
												color="#d079e9"
												className="create-appointment-btn"
												onClick={(e) => {
													openRazorPayModal(e);
												}}
												disabled={patientDetails?.id ? false : true}
											>
												Proceed to Pay
											</button>
										)}
										{appointment?.apptId && (
											<button
												color="#d079e9"
												className="create-appointment-btn"
												onClick={(e) => {
													bookOrRescheduleAppointment(e);
												}}
												disabled={patientDetails?.id ? false : true}
											>
												Reschedule Appointment
											</button>
										)}
									</div>
								</div>
							)}
					</div>
				</div>
			</div>
		</ThemeProvider>
	);
}
